<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 13.211 13.211"
  >
    <defs>
      <!-- <style>
        .a {
          fill: #267c26;
        }
      </style> -->
    </defs>
    <path
      :style="iconStyle"
      class="a"
      d="M8.105,1.5a6.605,6.605,0,1,0,6.605,6.605A6.6,6.6,0,0,0,8.105,1.5Zm1.674,9L8.105,8.823,6.432,10.5a.507.507,0,1,1-.718-.718L7.388,8.105,5.714,6.432a.507.507,0,0,1,.718-.718L8.105,7.388,9.779,5.714a.507.507,0,0,1,.718.718L8.823,8.105,10.5,9.779a.51.51,0,0,1,0,.718A.5.5,0,0,1,9.779,10.5Z"
      transform="translate(-1.5 -1.5)"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '10',
    },
    width: {
      type: String,
      default: '10',
    },
    color: {
      type: String,
      default: 'label-text',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
